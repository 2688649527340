<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="padding: 10px 20px">
        <el-button
          @click="goBack"
          type="primary"
          size="small"
          style="position: absolute; right: 10px; top: 10px; min-width: 80px"
          >返回</el-button
        >
        <el-col :span="20">
          <el-descriptions title="货源信息" :column="4" border>
            <el-descriptions-item label="货源编号" :span="4">{{
              addForm.pno
            }}</el-descriptions-item>
            <el-descriptions-item label="发货公司">{{
              addForm.sendOwner
            }}</el-descriptions-item>
            <el-descriptions-item label="收货公司">{{
              addForm.receiveOwner
            }}</el-descriptions-item>
            <el-descriptions-item label="货物类型">{{
              addForm.cargoType
            }}</el-descriptions-item>
            <el-descriptions-item label="货物名称">{{
              addForm.cargoTypeName
            }}</el-descriptions-item>
            <el-descriptions-item label="发货地址">{{
              addForm.sendProvince +
              "/" +
              addForm.sendCity +
              "/" +
              addForm.sendCounty
            }}</el-descriptions-item>
            <el-descriptions-item label="收货地址">{{
              addForm.receiveProvince +
              "/" +
              addForm.receiveCity +
              "/" +
              addForm.receiveCounty
            }}</el-descriptions-item>
            <el-descriptions-item label="货物吨数">
              {{ addForm.plantons + "(吨)" }}
            </el-descriptions-item>
            <el-descriptions-item label="运费单价">
              {{ addForm.freightCharge + "(吨/元)" }}
            </el-descriptions-item>

            <el-descriptions-item label="发货详细地址">{{
              addForm.sendAddressDetail
            }}</el-descriptions-item>
            <el-descriptions-item label="收货详细地址">{{
              addForm.receiveAddressDetail
            }}</el-descriptions-item>
            <el-descriptions-item label="装车日期">{{
              addForm.loadingDate
            }}</el-descriptions-item>
            <el-descriptions-item label="车辆类型">{{
              addForm.truckType
            }}</el-descriptions-item>
            <el-descriptions-item label="发货联系人">{{
              addForm.sendContacts
            }}</el-descriptions-item>
            <el-descriptions-item label="收货联系人">{{
              addForm.receiveContacts
            }}</el-descriptions-item>
            <el-descriptions-item label="装车费">
              {{ addForm.loadingCharge + "元/吨" }}
            </el-descriptions-item>
            <el-descriptions-item label="卸车费">
              {{ addForm.unloadingCharge + "元/吨" }}
            </el-descriptions-item>
            <el-descriptions-item label="发货联系电话">{{
              addForm.sendPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="收货联系电话">{{
              addForm.receivePhone
            }}</el-descriptions-item>
            <el-descriptions-item label="委托人">{{
              addForm.cargoAgent
            }}</el-descriptions-item>
            <el-descriptions-item label="委托人联系电话">{{
              addForm.cargoAgentPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="装卸要求" :span="4">{{
              addForm.cargoAgentPhone
            }}</el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>
      <el-row style="padding: 10px 20px">
        <el-descriptions title="已派车辆磅单详情"></el-descriptions>
        <el-table :data="driverTable" max-height="400px">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="fleetName" label="车队名称"> </el-table-column>
          <el-table-column prop="plate" label="车牌号"> </el-table-column>
          <el-table-column prop="drivername" label="司机姓名">
          </el-table-column>
          <el-table-column label="运单状态">
            <template slot-scope="scope">
              {{ enumStatus[scope.row.mergeStatus] }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.mergeStatus == 10">
                <el-button size="mini" type="text" @click="handlePass()"
                  >通过</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  style="color: rgb(254, 90, 36)"
                  @click="handleReject()"
                  >拒绝</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import { mapState } from "vuex";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        sendOwner: "",
        receiveOwner: "",
        pno: "",
        cargoType: "",
        cargoTypeName: "",
        truckType: "",
        sendProvince: "",
        sendCity: "",
        sendCounty: "",
        sendContacts: "",
        sendPhone: "",
        sendAddressDetail: "",
        receiveProvince: "",
        receiveCity: "",
        receiveCounty: "",
        receiveAddressDetail: "",
        receiveContacts: "",
        receivePhone: "",
        loadingDate: "",
        plantons: "",
        freightCharge: "",
        loadingCharge: "",
        unloadingCharge: "",
        cargoAgent: "",
        cargoAgentPhone: "",
      },
      enumStatus: {
        0: "待接单",
        1: "已拒绝",
        2: "已接单",
        10: "待审核",
        11: "未通过",
        20: "待启运",
        21: "待送达",
        22: "已完工",
      },
      activeName: "first",
      fleetTable: [],
      driverTable: [],
      showDriver: false,
    };
  },
  components: {
    mTitle,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.getDetails();
    this.getDriverTable();
  },
  methods: {
    getDetails() {
      this.$http(
        "api1",
        "/api/biz/PlanInfo/info/" + this.$route.query.id,
        "get",
        {}
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key];
        }
        this.activeName = "second";
        this.getDriverTable();
      });
    },
    getDriverTable() {
      this.$http(
        "api1",
        "/api/biz/TransportBill/dispatchTransportBill/" +
          this.$route.query.transportBillId,
        "get",
        {}
      ).then((res) => {
        if (res.data.code != 0) {
          this.$message.error(res.data.msg);
          return;
        }
        this.driverTable = [res.data.data];
      });
    },
    handlePass() {
      this.$messageBox
        .confirm("确认" + "通过" + "该运单,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/TransportBill/auditAccept",
            "post",
            {
              transportBillId: this.$route.query.transportBillId,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        });
    },
    handleReject() {
      this.$messageBox
        .prompt("请输入驳回原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(async ({ value }) => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/TransportBill/auditRefuse",
            "post",
            {
              transportBillId: this.$route.query.transportBillId,
              refuseReason: value,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        })
        .catch(() => {});
    },
    goBack() {
      this.$router.go(-1);
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
